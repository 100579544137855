import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-classes"></a><h2>Psionic Classes
    </h2>
    <a id="the-power-point-reserve"></a><h5>THE POWER POINT RESERVE</h5>
Psionic characters fuel their abilities through a pool, or reserve, of
power points. Your power point reserve is equal to your base power
points gained from your class, bonus power points from a high key
ability score (see Abilities and Manifesters, below), and any
additional bonus power points from sources such as your character race
and feat selections.
    <a id="milticlass-psionic-characters"></a><p><span style={{
        "fontWeight": "bold"
      }}>Multiclass Psionic Characters</span>
      <p>{`If you have levels in more than one psionic class, you combine your
power points from each class to make up your reserve. You can use these
power points to manifest powers from any psionic class you have.`}</p>
      <p>{`While you maintain a single reserve of power points from your class,
race, and feat selections, you are still limited by the manifester
level you have achieved with each power you know. `}</p></p>
    <a id="abilities-and-manifesters"></a><h5>ABILITIES AND MANIFESTERS</h5>
The ability that your powers depend on -your key ability score as a
manifester- is related to what psionic class (or classes) you have
levels in: Intelligence (psion), Wisdom (psychic warrior), and Charisma
(wilder). The modifier for this ability is referred to as your key
ability modifier. If your character&#8217;s key ability score is 9 or lower,
you can&#8217;t manifest powers from that psionic class.
    <p>Just as a high Intelligence score grants bonus spells to a
wizard and a high Wisdom score grants bonus spells to a cleric, a
character who manifests powers (psions, psychic warriors, and wilders)
gains bonus power points according to his key ability score. Refer to
Table: Ability Modifiers and Bonus Power Points.</p>
    <p>How To Determine Bonus Power Points: Your key ability score
grants you additional power points equal to your key ability modifier x
your manifester level x1/2. Table: Ability Modifiers and Bonus Power
Points shows these calculations for class levels 1st through 20th and
key ability scores from 10 to 41.</p>
    <a id="table-ability-modifiers-and-bonus-power-points"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Ability Modifiers and
Bonus Power Points</span>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th>&nbsp;</th>
          <th colSpan="20" className="center" rowSpan="1">-----------------
Bonus Power Points (by Class Level) -----------------
          </th>
        </tr>
        <tr>
          <th style={{
            "width": "15%"
          }}>Ability Score
          </th>
          <th style={{
            "width": "4%"
          }}>1st
          </th>
          <th style={{
            "width": "4%"
          }}>2nd</th>
          <th style={{
            "width": "4%"
          }}>3rd
          </th>
          <th style={{
            "width": "4%"
          }}>4th
          </th>
          <th style={{
            "width": "4%"
          }}>5th</th>
          <th style={{
            "width": "4%"
          }}>6th</th>
          <th style={{
            "width": "4%"
          }}>7th</th>
          <th style={{
            "width": "4%"
          }}>8th</th>
          <th style={{
            "width": "4%"
          }}>9th</th>
          <th style={{
            "width": "4%"
          }}>10th</th>
          <td style={{
            "verticalAlign": "top",
            "width": "4%",
            "fontWeight": "bold"
          }}>11th
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "4%",
            "fontWeight": "bold"
          }}>12th
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "4%",
            "fontWeight": "bold"
          }}>13th
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "4%",
            "fontWeight": "bold"
          }}>14th
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "4%",
            "fontWeight": "bold"
          }}>15th
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "4%",
            "fontWeight": "bold"
          }}>16th
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "4%",
            "fontWeight": "bold"
          }}>17th
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "4%",
            "fontWeight": "bold"
          }}>18th
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "4%",
            "fontWeight": "bold"
          }}>19th
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "4%",
            "fontWeight": "bold"
          }}>20th
          </td>
        </tr>
        <tr>
          <td>10-11</td>
          <td>0
          </td>
          <td>0
          </td>
          <td>0
          </td>
          <td>0
          </td>
          <td>0
          </td>
          <td>0
          </td>
          <td>0
          </td>
          <td>0
          </td>
          <td>0
          </td>
          <td>0
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0
          </td>
        </tr>
        <tr className="odd-row">
          <td>12-13</td>
          <td>0
          </td>
          <td>1
          </td>
          <td>1
          </td>
          <td>2
          </td>
          <td>2
          </td>
          <td>3
          </td>
          <td>3
          </td>
          <td>4
          </td>
          <td>4
          </td>
          <td>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>10
          </td>
        </tr>
        <tr>
          <td>14-15</td>
          <td>1
          </td>
          <td>2
          </td>
          <td>3
          </td>
          <td>4
          </td>
          <td>5
          </td>
          <td>6
          </td>
          <td>7
          </td>
          <td>8
          </td>
          <td>9
          </td>
          <td>10
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>11
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>12
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>13
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>14
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>16
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>17
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>18
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>19
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>20
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>16-17
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>3
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>4
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>6
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>7
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>9
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>10
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>12
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>13
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>16
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>18
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>19
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>21
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>22
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>24
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>25
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>27
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>28
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>30
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>18-19</td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>2
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>4
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>6
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>8
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>10
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>12
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>14
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>16
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>18
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>20
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>22
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>24
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>26
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>28
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>30
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>32
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>34
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>36
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>38
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>40
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>20-21</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>2
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>5
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>7
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>10
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>12
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>15
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>17
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>20
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>22
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>25
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>27
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>30
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>32
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>35
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>37
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>40
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>42
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>45
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>47
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>50
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>22-23</td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>3
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>6
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>9
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>12
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>15
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>18
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>21
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>24
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>27
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>30
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>33
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>36
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>39
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>42
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>45
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>48
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>51
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>54
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>57
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>60
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>24-25</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>3
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>7
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>10
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>14
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>17
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>21
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>24
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>28
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>31
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>35
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>38
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>42
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>45
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>49
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>52
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>56
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>59
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>63
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>66
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>70
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>26-27</td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>4
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>8
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>12
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>16
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>20
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>24
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>28
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>32
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>36
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>40
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>44
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>48
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>52
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>56
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>60
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>64
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>68
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>72
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>76
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>80
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>28-29</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>4
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>9
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>13
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>18
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>22
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>27
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>31
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>36
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>40
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>45
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>49
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>54
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>58
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>63
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>67
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>72
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>76
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>81
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>85
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>90
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>30-31</td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>5
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>10
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>15
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>20
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>25
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>30
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>35
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>40
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>45
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>50
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>55
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>60
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>65
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>70
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>75
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>80
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>85
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>90
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>95
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>100
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>32-33</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>5
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>11
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>16
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>22
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>27
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>33
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>38
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>44
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>49
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>55
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>60
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>66
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>71
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>77
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>82
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>88
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>93
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>99
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>104
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>110
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>34-35</td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>6
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>12
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>18
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>24
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>30
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>36
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>42
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>48
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>54
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>60
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>66
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>72
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>78
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>84
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>90
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>96
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>102
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>108
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>114
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>120
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>36-37</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>6
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>13
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>19
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>26
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>32
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>39
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>45
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>52
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>58
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>65
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>71
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>78
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>84
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>91
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>97
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>104
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>110
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>117
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>123
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>130
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>38-39</td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>7
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>14
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>21
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>28
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>35
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>42
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>49
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>56
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>63
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>70
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>77
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>84
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>91
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>98
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>105
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>112
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>119
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>126
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>133
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>140
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>40-41</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>7
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>15
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>22
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>30
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>37
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>45
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>52
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>60
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>67
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>75
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>82
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>90
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>97
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>105
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>112
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>120
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>127
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>135
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>142
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>150
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <a id="table-random-starting-gold"></a><span style={{
        "fontWeight": "bold"
      }}>Table: Random Starting Gold</span>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "75px"
          }}>Class</th>
          <th>Amount (Average)
          </th>
        </tr>
        <tr className="odd-row">
          <td>Psion
          </td>
          <td>3d4 x10 (75 gp)
          </td>
        </tr>
        <tr>
          <td>Psychic Warrior
          </td>
          <td>5d4 x10 (125 gp)
          </td>
        </tr>
        <tr className="odd-row">
          <td>Soulknife
          </td>
          <td>5d4 x10 (125 gp)
          </td>
        </tr>
        <tr>
          <td>Wilder
          </td>
          <td>4d4 x10 (100 gp)
          </td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      